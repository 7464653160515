// this file replaces "Software-Asset-List.js as the view displayed at the softwareAssets endpoint"

import React, { Component } from "react";

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import Container from 'react-bootstrap/Container';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Grid,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Button,
    Paper,
    Pill,
    Select,
    Switch,
    Tooltip,
    Flex,
    Modal
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import contractClasses from './View-Contracts-and-Software.module.css';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconFilterX, IconPlus } from '@tabler/icons-react';

import Navbar from '../../components/Navbar';
//import classes from '../TableSort.module.css';

//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from 'react-bootstrap/Tooltip';
//import Modal from 'react-bootstrap/Modal';

//import {Link} from 'react-router-dom';

//import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

//import { Container } from 'reactstrap';
//import DataTable from '@bit/adeoy.utils.data-table';

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import { faSearch, faFilter, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
//import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
//import { Form } from "react-bootstrap";
//import Form from 'react-bootstrap/Form';

import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';

//import HardwareAssetDataService from '../../services/hardware-asset-activities';
import SoftwareAssetDataService from '../../services/software-asset-activities';

import {
    UPDATE_SORT_BY_SOFTWARE,
    UPDATE_SORT_ORDER_SOFTWARE,
    UPDATE_SEARCH_TEXT_SOFTWARE,
    UPDATE_SELECTED_WARRANTY_STATUS_SOFTWARE,
    UPDATE_SELECTED_EXPIRATION_START_DATE_SOFTWARE,
    UPDATE_SELECTED_EXPIRATION_END_DATE_SOFTWARE,
    UPDATE_SELECTED_LICENSE_STATUS_SOFTWARE,
    UPDATE_SELECTED_LICENSE_EXPIRATION_START_DATE_SOFTWARE,
    UPDATE_SELECTED_LICENSE_EXPIRATION_END_DATE_SOFTWARE,
    UPDATE_SELECTED_HAS_DEVICES_REMAINING_SOFTWARE,
    UPDATE_SELECTED_TENANT_ID_SOFTWARE,
    UPDATE_SELECTED_ASSIGNED_TO_USER_SOFTWARE,
    UPDATE_SELECTED_ASSIGNED_TO_ASSET_SOFTWARE,
    UPDATE_SELECTED_LICENSE_NUMBER_SOFTWARE,


    UPDATE_SELECTED_CONTRACT_CUSTOMER,
    UPDATE_SELECTED_CONTRACT_TYPE,
    UPDATE_SELECTED_CONTRACT_STATUS,
    UPDATE_SELECTED_CONTRACT_VENDOR,
    UPDATE_SELECTED_CONTRACT_END_DATE,
    UPDATE_CONTRACT_SEARCH_TEXT,
    UPDATE_SELECTED_SOFTWARE_CONTRACT_DATA_VIEW,
    UPDATE_CONTRACT_SORT_BY_AND_ORDER,
    RESET_CONTRACT_FILTERS,

    UPDATE_SOFTWARE_ASSET_STATUS_FILTER,
    UPDATE_SOFTWARE_ASSET_MANAGED_BY_FILTER,
    UPDATE_SOFTWARE_ASSET_TYPE_FILTER,
    UPDATE_SOFTWARE_PUBLISHER_FILTER,
    UPDATE_SOFTWARE_ASSET_SEARCH_TEXT,
    UPDATE_SOFTWARE_ASSET_SORT_BY_AND_SORT_ORDER,
    RESET_SOFTWARE_ASSET_FILTERS
} from '../../features/actions/Software-Asset-Filter-Actions';

import classes from '../TableSort.module.css';

function Th(props) {
    const Icon = props.sorted ? (props.reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={props.onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={800} fz="sm">
                        {props.children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

class ViewContractsAndSoftware extends Component {
    constructor(props) {
        super(props);

        this.scrollerRef = React.createRef();

        this.state = {
            customers: [{id: 0, name: "All"}],       // this will come from the backend as an array of objects [{id: 1, name: "LPA"}, {id: 2, name: "ProGroup"}] - hardcoding the All option with id = 0
            selectedCustomer: "All", // name of the customer user has selected from button list
            selectedCustomerId: 0, // ID of the selected customer from the button list

            dataView: true,     // we're going to say true = View Contracts, false = View Software


            // [{id: 1, company_name: "LPA", contract_name: "Microsoft 365 License", contract_type: "Software License", contract_status: "Active", renewal_status: "Pending", contract_number: "CNTR-2", vendor_name: "Microsoft", end_date: "02/14/25", days_left: 33}]
            contractList: [
                /* {id: 1, company_name: "LPA", contract_name: "Microsoft 365 License", contract_type: "Software License", contract_status: "Active", renewal_status: "Pending", contract_number: "CNTR-2", vendor_name: "Microsoft", end_date: "02/14/25", days_left: 33},
                {id: 3, company_name: "LPA", contract_name: "Freshservice Demo", contract_type: "Software License", contract_status: "Active", renewal_status: "Pending", contract_number: "CNTR-3", vendor_name: "Freshdesk", end_date: "03/31/24", days_left: 20},
                {id: 2, company_name: "LPA", contract_name: "Sample Apple Contract", contract_type: "Lease", contract_status: "Inactive", renewal_status: "Complete", contract_number: "CNTR-4", vendor_name: "Apple", end_date: "01/03/23", days_left: -45} */
            ],
            
            vendors: [{id: 0, vendor_name: "All"}],     // for populating the contract filter   [{id: 1, vendor_name: "Ingram Micro"}]
        

            /* The following items pertain to values selected in the filters */
            selectedContractType: "All",
            selectedContractStatus: "All",
            selectedVendorId: 0,
            selectedVendorName: "All",
            selectedEndDate: null,
            searchTextContract: "",
            typingTimeout: 0,
            sortBy: "contract_name",
            sortOrder: "ASC",
            offset: 0,      // how many records have been returned by the contract endpoint - used for infinite scroll
            scrollUpdateComplete: true, //tells scroll event handler if it can call for more tickets
            totalNumberOfContracts: 0,      // the count of rows based on query parameters provided

            greenZoneDays: 90,      // if this number of days or above, display the expiry date in green
            redZoneDays: 60,        // if this number of days or less, display the expiry date in red (everything in between values is warning zone orange)


            // SOFTWARE VIEW VALUES APPEAR BELOW THIS LINE
            //[{id: 1, company_name: "LPA", software_name: "Office 365", status: "Managed", contract_value: 2500.00, managed_by: "Bob Hoskiss", user_count: 1, install_count: 0, type: "Saas", publisher: "Microsoft"}]
            software: [],
            publishers: [{id: 0, name_of_manufacturer: "All"}],   // for publisher filter drop down
            managedByList: [{id: 0, name: "All"}],  // for managed by filter drop down

            selectedSoftwareStatus: "All",
            selectedSoftwareManagedById: 0,
            selectedSoftwareManagedByName: "All",
            selectedSoftwareType: "All",
            selectedSoftwarePublisherId: 0,
            selectedSoftwarePublisherName: "All",
            searchTextSoftware: "",
            softwareSortBy: "software_name",
            softwareSortOrder: "ASC",
            typingTimeoutSoftware: 0,
            totalNumberOfSoftware: 0,        // the count of rows based on query parameters provided

            show_customer_modal: false,
            create_customer_name_entered: "",
            canSeeErrorMessageCreateCustomer: false,
            canSeeDuplicateNameErrorCreateCustomer: false
        }
    }


    componentDidMount() {

        //console.log("Global state value of end date is:", this.props.softwareAssetFilter.selectedContractEndDate);

        window.addEventListener('scroll', this.handleAssignedTableScroll, { passive: true });

        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    console.log("Global state for software sort by: ", this.props.softwareAssetFilter.softwareSortBy);

                    // get the customer list for this tenant
                    SoftwareAssetDataService.getCustomers(token, this.props.loggedStatus.primary_tenant_id).then(response => {
                        console.log(response);

                        this.setState({
                            customers: [...this.state.customers, ...response.data.results]
                        });

                        // get drop down values for the view software filters
                        SoftwareAssetDataService.getDropdownValuesForSoftwareAssetFormAndFilters(token, this.props.loggedStatus.primary_tenant_id).then(response => {

                            this.setState({
                                publishers: [...this.state.publishers, ...response.data.publishers],
                                managedByList: [...this.state.managedByList, ...response.data.managers]
                            });

                            // get the vendor list for the drop down
                            SoftwareAssetDataService.getDropdownValuesForContractForm(token, this.props.loggedStatus.primary_tenant_id).then(response => {

                                console.log(response);

                                for(let i = 0; i < response.data.vendor_list.length; i++) {
                                    response.data.vendor_list[i].vendor_name = response.data.vendor_list[i].name_of_vendor;
                                }
                                // set the initial state with the global values, then get contracts
                                this.setState({
                                    vendors: [...this.state.vendors, ...response.data.vendor_list],
                                    dataView: this.props.softwareAssetFilter.selectedSoftwareContractDataView,
                                    selectedCustomer: this.props.softwareAssetFilter.selectedContractCustomerName,
                                    selectedCustomerId: this.props.softwareAssetFilter.selectedContractCustomerId,
                                    selectedContractType: this.props.softwareAssetFilter.selectedContractType,
                                    selectedContractStatus: this.props.softwareAssetFilter.selectedContractStatus,
                                    selectedVendorId: this.props.softwareAssetFilter.selectedContractVendorId,
                                    selectedVendorName: this.props.softwareAssetFilter.selectedContractVendorName,
                                    selectedEndDate: this.props.softwareAssetFilter.selectedContractEndDate,
                                    searchTextContract: this.props.softwareAssetFilter.contractSearchText,
                                    sortBy: this.props.softwareAssetFilter.sortByContract,
                                    sortOrder: this.props.softwareAssetFilter.sortOrderContract,

                                    selectedSoftwareStatus: this.props.softwareAssetFilter.selectedSoftwareStatus,
                                    selectedSoftwareManagedById: this.props.softwareAssetFilter.selectedSoftwareManagedById,
                                    selectedSoftwareManagedByName: this.props.softwareAssetFilter.selectedSoftwareManagedByName,
                                    selectedSoftwareType: this.props.softwareAssetFilter.selectedSoftwareType,
                                    selectedSoftwarePublisherId: this.props.softwareAssetFilter.selectedSoftwarePublisherId,
                                    selectedSoftwarePublisherName: this.props.softwareAssetFilter.selectedSoftwarePublisherName,
                                    searchTextSoftware: this.props.softwareAssetFilter.searchTextSoftware,
                                    softwareSortBy: this.props.softwareAssetFilter.softwareSortBy,
                                    softwareSortOrder: this.props.softwareAssetFilter.softwareSortOrder
                                }, () => {
                                    if(this.state.dataView === true) {
                                        this.getContracts();
                                    }

                                    else {
                                        this.getSoftwareAssets();
                                    }
                                });
                            })
                        })

                    });

                    
                }

            });
        }

        
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleAssignedTableScroll);
    }

    handleContractSoftwareCustomerChange(customer_name) {
        if(customer_name !== this.state.selectedCustomer) {
            let newCustomerId = this.state.customers.find(o => o.name === customer_name).id;

            // UPDATE GLOBAL STATE HERE !!!
            this.props.UPDATE_SELECTED_CONTRACT_CUSTOMER({customer_id: newCustomerId, customer_name: customer_name});

            this.setState({
                selectedCustomer: customer_name,
                selectedCustomerId: newCustomerId,
                offset: 0
            }, () => {
                if(this.state.dataView === true) { this.getContracts(); }
                else { this.getSoftwareAssets(); }
            });

        }
    }


    // when this fxn receives a false, we'll be viewing Software, when it receives a true, we'll be viewing Contracts
    // when going from contracts to software, remove the contract list from state to save space v.v. when switching the other way
    handleViewChange(new_slider_value) {
        this.setState({
            dataView: new_slider_value,
            offset: 0
        }, () => {
            if(this.state.dataView === true) { this.getContracts(); }
            else { this.getSoftwareAssets(); }
        });

        // update the global state
        this.props.UPDATE_SELECTED_SOFTWARE_CONTRACT_DATA_VIEW(new_slider_value);
    }

    goToSpecificContract(contract_id) {
        console.log("You'd be navigating to contract id: ", contract_id);

        this.props.history.push(`/contracts/${contract_id}`);
    }


    goToSpecificSoftwareAsset(asset_id) {
        this.props.history.push(`/softwareAssetDetails/${asset_id}`)
    }


    // calls backend endpoint with filters provided to get the necessary list of contracts
    getContracts() {
        console.log("Going to send for the next round of contracts");
        console.log("Selected customer: ", this.state.selectedCustomer);
        console.log("Selected customer id: ", this.state.selectedCustomerId);
        console.log("Selected contract type: ", this.state.selectedContractType);
        console.log("Selected contract status: ", this.state.selectedContractStatus);
        console.log("Selected vendor id: ", this.state.selectedVendorId);
        console.log("Selected vendor name: ", this.state.selectedVendorName);
        console.log("Selected end date: ", this.state.selectedEndDate);
        console.log("Search Text is: ", this.state.searchTextContract);
        console.log("Sort By: ", this.state.sortBy);
        console.log("Sort Order: ", this.state.sortOrder);
        console.log("Offset: ", this.state.offset);

        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
            // if the token can't be refreshed, force a logout
            if (token === undefined || token === null || token === "") {
                console.log("Token was expired and can't refresh, so force logging out this user!");
                this.props.LOGOUT();        // logout of global state
                this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
            }

            else {
                this.props.ACCESSTOKEN(token);

                let endDate;

                if(this.state.selectedEndDate !== null && this.state.selectedEndDate !== "") {
                    endDate = new Date(this.state.selectedEndDate);
                    endDate = endDate.toISOString();
                    endDate = endDate.split("T")[0]; 
                }
                
                else {
                    endDate = ""
                }

                SoftwareAssetDataService.getAllContracts(token, this.props.loggedStatus.primary_tenant_id, this.state.selectedCustomerId, this.state.selectedContractType, this.state.selectedContractStatus, this.state.selectedVendorId, endDate, this.state.searchTextContract, this.state.sortBy, this.state.sortOrder, this.state.offset).then(response => {
                    console.log(response);

                    let newContractList;

                    if(this.state.offset === 0) {   // if this is first pull, replace the contracts
                        newContractList = response.data.results
                    }

                    else {      // we got here through a scroll, so add these new results to the end of the previous ones
                        newContractList = [...this.state.contractList, ...response.data.results];
                        this.setState({scrollUpdateComplete: true})
                    }

                    this.setState({
                        contractList: newContractList,
                        totalNumberOfContracts: response.data.row_count,
                        offset: this.state.offset + 10          // we've returned 10 items so update value
                    });
                });
            }

        });
    }


    getSoftwareAssets() {
        console.log("Going to send for the next round of software assets");
        console.log("Selected customer: ", this.state.selectedCustomer);
        console.log("Selected customer id: ", this.state.selectedCustomerId);
        console.log("Software Status: ", this.state.selectedSoftwareStatus);
        console.log("Manager Id: ", this.state.selectedSoftwareManagedById);
        console.log("Manager Name: ", this.state.selectedSoftwareManagedByName);
        console.log("Type: ", this.state.selectedSoftwareType);
        console.log("Publisher Id: ", this.state.selectedSoftwarePublisherId);
        console.log("Publisher Name: ", this.state.selectedSoftwarePublisherName);
        console.log("Search Text: ", this.state.searchTextSoftware);
        console.log("Sort By: ", this.state.softwareSortBy);
        console.log("Sort Order: ", this.state.softwareSortOrder);

        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
            // if the token can't be refreshed, force a logout
            if (token === undefined || token === null || token === "") {
                console.log("Token was expired and can't refresh, so force logging out this user!");
                this.props.LOGOUT();        // logout of global state
                this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
            }

            else {
                this.props.ACCESSTOKEN(token);

                SoftwareAssetDataService.getSoftwareAssetsUpdatedVersion(token, this.props.loggedStatus.primary_tenant_id, this.state.selectedCustomerId, this.state.selectedSoftwareStatus, this.state.selectedSoftwareManagedById, this.state.selectedSoftwareType, this.state.selectedSoftwarePublisherId, this.state.searchTextSoftware, this.state.softwareSortBy, this.state.softwareSortOrder, this.state.offset).then(response => {
                    console.log("Software response: ", response);

                    let newSoftwareList;

                    if(this.state.offset === 0) {       // if this was first pull, replace existing software list
                        console.log("First pull");
                        newSoftwareList = response.data.results;
                    }

                    else {      // we got here through a scroll, so add new results at end of current list
                        newSoftwareList = [...this.state.software, ...response.data.results];

                        this.setState({ scrollUpdateComplete: true });
                    }

                    this.setState({
                        software: newSoftwareList,
                        totalNumberOfSoftware: response.data.row_count,
                        offset: this.state.offset + 10      // we've returned 10 items so update value
                    });

                });
            }
        });
    }


    handleContractTypeFilterChange(selected_type) {
        
        this.setState({
            selectedContractType: selected_type,
            offset: 0
        }, () => this.getContracts());

        // Set the global state here
        this.props.UPDATE_SELECTED_CONTRACT_TYPE(selected_type);
    }

    handleContractStatusChange(selected_status) {
        this.setState({
            selectedContractStatus: selected_status,
            offset: 0
        }, () => this.getContracts());

        // Set the global state here
        this.props.UPDATE_SELECTED_CONTRACT_STATUS(selected_status);
    }

    handleVendorChange(selected_vendor_name) {

        let newVendorId = this.state.vendors.find(o => o.vendor_name === selected_vendor_name).id
        this.setState({
            selectedVendorId: newVendorId,
            selectedVendorName: selected_vendor_name,
            offset: 0
        }, () => this.getContracts());

        // Set the global state here
        this.props.UPDATE_SELECTED_CONTRACT_VENDOR({vendor_id: newVendorId, vendor_name: selected_vendor_name});
    }

    handleEndDateChange(selected_date) {

        let dateToUse;
        
        if(selected_date === null) {
            dateToUse = "";
        }
        
        else {
            dateToUse = selected_date;
        }

        this.setState({
            selectedEndDate: dateToUse,
            offset: 0
        }, () => this.getContracts());

        // Set the global state here
        this.props.UPDATE_SELECTED_CONTRACT_END_DATE(dateToUse);
    }

    onChangeSearchTextContract = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            searchTextContract: e.target.value,
            offset: 0,

            typingTimeout: setTimeout(() => {
                // update the global state
                this.props.UPDATE_CONTRACT_SEARCH_TEXT(e.target.value);
                // ask for the new set of contracts HERE !!!
                this.getContracts();
            }, 1500)
        }/* , () => console.log("You'll update global state of search text here!") */);
    }

    onChangeSearchTextSoftware = (e) => {
        if(this.state.typingTimeoutSoftware) {
            clearTimeout(this.state.typingTimeoutSoftware);
        }

        this.setState({
            searchTextSoftware: e.target.value,
            offset: 0,

            typingTimeoutSoftware: setTimeout(() => {
                // set global state, then get software here
                console.log("You're in the code right above global state update");
                this.props.UPDATE_SOFTWARE_ASSET_SEARCH_TEXT(e.target.value);
                this.getSoftwareAssets();
            }, 1500)
        }/* , () => console.log("You'll update global state of search text here!") */);
    }

    // fired when user clicks a table header - sorts contracts by column and flips sort order
    handleSortByAndOrderChange(new_column_name) {

        let newSortOrder;
        if(this.state.sortOrder === "ASC") {
            newSortOrder = "DESC";
        }

        else {
            newSortOrder = "ASC";
        }

        // if this is a new column, change both the column sorted and the order
        if(new_column_name !== this.state.sortBy) {
            this.setState({
                sortBy: new_column_name,
                sortOrder: newSortOrder,
                offset: 0
            }, () => this.getContracts());
        }

        // otherwise it was the same column, so just change the sort order
        else {
            this.setState({
                sortOrder: newSortOrder,
                offset: 0
            }, () => this.getContracts());
        }

        // update global state for sortBy and sortOrder here
        this.props.UPDATE_CONTRACT_SORT_BY_AND_ORDER({sortBy: new_column_name, sortOrder: newSortOrder});
    }

    handleSoftwareSortByAndOrderChange(new_column_name) {
        let newSortOrder;
        if(this.state.softwareSortOrder === "ASC") {
            newSortOrder = "DESC";
        }

        else {
            newSortOrder = "ASC";
        }

        // if this is a new column, change both the column sorted and the order
        if(new_column_name !== this.state.softwareSortBy) {
            this.setState({
                softwareSortBy: new_column_name,
                softwareSortOrder: newSortOrder,
                offset: 0
            }, () => this.getSoftwareAssets());
        }

        // otherwise it was the same column, so just change the sort order
        else {
            this.setState({
                softwareSortOrder: newSortOrder,
                offset: 0
            }, () => this.getSoftwareAssets());
        }

        // update global state for sortBy and sortOrder here
        //this.props.UPDATE_CONTRACT_SORT_BY_AND_ORDER({sortBy: new_column_name, sortOrder: newSortOrder});
        this.props.UPDATE_SOFTWARE_ASSET_SORT_BY_AND_SORT_ORDER({softwareSortBy: new_column_name, softwareSortOrder: newSortOrder});
    }

    resetContractFilters() {
        this.setState({
            selectedContractType: "All",
            selectedContractStatus: "All",
            selectedVendorId: 0,
            selectedVendorName: "All",
            selectedEndDate: null,
            searchTextContract: "",
            sortBy: "contract_name",
            sortOrder: "ASC",
            offset: 0
        }, () => this.getContracts());

        // Set the global state here
        this.props.RESET_CONTRACT_FILTERS();
    }


    resetSoftwareFilters() {
        this.setState({
            selectedSoftwareStatus: "All",
            selectedSoftwareManagedById: 0,
            selectedSoftwareManagedByName: "All",
            selectedSoftwareType: "All",
            selectedSoftwarePublisherId: 0,
            selectedSoftwarePublisherName: "All",
            searchTextSoftware: "",
            softwareSortBy: "software_name",
            softwareSortOrder: "ASC",
            offset: 0
        }, () => this.getSoftwareAssets());

        // Set the global state here
        this.props.RESET_SOFTWARE_ASSET_FILTERS();
    }


    handleAssignedTableScroll = () => {
        if (window.scrollY === 0) {
            this.setState({ scrollUpdateComplete: true })
        }

        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        //const bottom = window.scrollY + window.innerHeight >= document.body.scrollHeight + 10 && window.scrollY > 100;
        //if (bottom) {
        if(windowHeight + scrollTop >= documentHeight - 1) {
            if (this.state.dataView === true && this.state.offset < this.state.totalNumberOfContracts && this.state.scrollUpdateComplete) {        // if we haven't gotten all the tickets yet, ask for the next 10
                this.setState({ scrollUpdateComplete: false })
                this.getContracts();
                //console.log("Ask for items");
            }

            else if (this.state.dataView === false && this.state.offset < this.state.totalNumberOfSoftware && this.state.scrollUpdateComplete) {
                this.setState({ scrollUpdateComplete: false })
                this.getSoftwareAssets();
            }
        }
    }


    handleSoftwareStatusFilterChange(newValue) {
        this.setState({
            selectedSoftwareStatus: newValue,
            offset: 0
        }, () => this.getSoftwareAssets());

        this.props.UPDATE_SOFTWARE_ASSET_STATUS_FILTER(newValue);
    }

    handleSoftwareManagedByFilterChange(newName) {
        let newManagerId = this.state.managedByList.find(o => o.name === newName).id;
        this.setState({
            selectedSoftwareManagedById: newManagerId,
            selectedSoftwareManagedByName: newName,
            offset: 0
        }, () => this.getSoftwareAssets());
        // let newVendorId = this.state.vendors.find(o => o.vendor_name === selected_vendor_name).id
        // this.setState({
        //     selectedVendorId: newVendorId,
        //     selectedVendorName: selected_vendor_name,
        //     offset: 0
        // }, () => this.getContracts());

        // // Set the global state here
        // this.props.UPDATE_SELECTED_CONTRACT_VENDOR({vendor_id: newVendorId, vendor_name: selected_vendor_name});
        this.props.UPDATE_SOFTWARE_ASSET_MANAGED_BY_FILTER({manager_id: newManagerId, manager_name: newName});
    }

    handleSoftwareTypeFilterChange(newValue) {
        this.setState({
            selectedSoftwareType: newValue,
            offset: 0
        }, () => this.getSoftwareAssets());

        this.props.UPDATE_SOFTWARE_ASSET_TYPE_FILTER(newValue);
    }

    handleSoftwarePublisherFilterChange(newName) {
        let newPublisherId = this.state.publishers.find(o => o.name_of_manufacturer === newName).id;
        this.setState({
            selectedSoftwarePublisherId: newPublisherId,
            selectedSoftwarePublisherName: newName,
            offset: 0
        }, () => this.getSoftwareAssets());

        this.props.UPDATE_SOFTWARE_PUBLISHER_FILTER({publisher_id: newPublisherId, publisher_name: newName});
    }

    closeCustomerModal() {
        this.setState({
            show_customer_modal: false,
            create_customer_name_entered: "",
            canSeeErrorMessageCreateCustomer: false,
            canSeeDuplicateNameErrorCreateCustomer: false
        });
    }

    openCustomerModal() {
        this.setState({
            show_customer_modal: true,
            create_customer_name_entered: "",
            canSeeErrorMessageCreateCustomer: false,
            canSeeDuplicateNameErrorCreateCustomer: false
        });
    }

    handleCreateCustomerNameChange(newValue) {
        this.setState({
            create_customer_name_entered: newValue
        });
    }

    submitCreateCustomerForm() {
        console.log("Submit customer form with name: ", this.state.create_customer_name_entered);

        let data = {
            customerName: this.state.create_customer_name_entered,
            tenantId: this.props.loggedStatus.primary_tenant_id
        };

        if (this.state.create_customer_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateCustomer: false,
                canSeeErrorMessageCreateCustomer: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateCustomer: false
            });

            // try to submit, but also check for duplicate name + tenant
            SoftwareAssetDataService.createCustomer(this.props.loggedStatus.accessToken, data).then(response => {
                if(response.data.results === "Duplicate Found!") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateCustomer: true
                    });
                }

                else if(response.data.results === "Request completed") {  // we've sucessfully added the entry
                    this.setState({
                        canSeeDuplicateNameErrorCreateCustomer: false,
                    });

                    // ask for the customer list again and refresh the buttons displayed
                    SoftwareAssetDataService.getCustomers(this.props.loggedStatus.accessToken, this.props.loggedStatus.primary_tenant_id).then(response => {
                        console.log(response);

                        this.setState({
                            customers: [{id: 0, name: "All"}, ...response.data.results]
                        });

                        this.closeCustomerModal();
                    });

                }
            })
        }
    }

    render() {

        if (!this.props.loggedStatus.loggedIn || (!this.props.userPermissions.can_see_assets /* && !this.props.userPermissions.can_edit_assets && !this.props.userPermissions.can_add_assets && !this.props.userPermissions.can_delete_assets */)) {
            return (
                <Redirect to='/' />
            );
        }

        return (
            <>
                <Navbar pageTitle={"Contracts and Software Assets"} />
                <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
                <Container fluid pt="20px" >
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                        >
                            {this.state.customers && this.state.customers.map((customer) => (
                                <Button
                                    h="36px"
                                    variant={this.state.selectedCustomer === customer.name ? "filled" : "default"}
                                    onClick={() => this.handleContractSoftwareCustomerChange(customer.name)}
                                >
                                    {customer.name}
                                </Button>))}
                                <span style={{marginTop: "5px"}}><Tooltip label="Click to create a new customer"><IconPlus color="#00abfb" style={{cursor: "pointer"}} onClick={() => this.openCustomerModal()} /></Tooltip></span>
                        </Flex>
                    </Paper>
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                        >
                        
                            {this.state.dataView === false && this.props.userPermissions.can_add_assets &&
                                <Button onClick={() => this.props.history.push(`/createSoftwareAsset`)}>+ Add New Software</Button>
                            }
                            {this.state.dataView === true && this.props.userPermissions.can_add_assets &&
                                <Button onClick={() => this.props.history.push(`/createContract`)}>+ Add New Contract</Button>
                            }
                            <span style={{marginLeft: "auto"}}><Switch size="xl" checked={this.state.dataView} onLabel="CONTRACTS" offLabel="SOFTWARE" onChange={(e) => this.handleViewChange(e.currentTarget.checked)}/></span>
                        </Flex>
                        {// if we are viewing contracts, show contract stuff - otherwise, show software stuff
                            this.state.dataView === true ? 
                            <div style={{marginTop: "30px"}}>
                                <Flex
                                    justify="flex-start"
                                    align="flex-start"
                                    direction="row"
                                    gap="xl"
                                >
                                    <Select
                                        label="Contract Type"
                                        placeholder="Pick a Value"
                                        data={["All", "Lease", "Maintenance", "Software License", "Warranty"]}
                                        value={this.state.selectedContractType}
                                        onChange={(e) => this.handleContractTypeFilterChange(e)}
                                    />

                                    <Select
                                        label="Contract Status"
                                        placeholder="Pick a Value"
                                        data={["All", "Active", "Inactive"]}
                                        value={this.state.selectedContractStatus}
                                        onChange={(e) => this.handleContractStatusChange(e)}
                                    />

                                    <Select
                                        label="Vendor"
                                        placeholder="Pick a Value"
                                        data={this.state.vendors && this.state.vendors.map((vendor) => (
                                            vendor.vendor_name
                                        ))}
                                        value={this.state.vendors && this.state.vendors.length > 0 && this.state.vendors.find((e) => e.id === this.state.selectedVendorId) ? 
                                            this.state.vendors.find((e) => e.id === this.state.selectedVendorId).vendor_name 
                                            :
                                            ""
                                        }
                                        onChange={(e) => this.handleVendorChange(e)}
                                    />

                                    <DateInput 
                                        label="Expires On or Before"
                                        placeholder="Pick a date"
                                        clearable
                                        value={this.state.selectedEndDate !== null ? this.state.selectedEndDate : ""}
                                        onChange={(e) => this.handleEndDateChange(e)}
                                    />

                                    <div style={{ marginTop: "32px" }}>
                                        <Tooltip label="Reset Filters"><Button variant="light" color="red" onClick={() => this.resetContractFilters()}><IconFilterX /></Button></Tooltip>
                                    </div>
                                </Flex>
                                <div style={{marginTop: "30px"}}>
                                    <TextInput
                                        placeholder="Search"
                                        mb="md"
                                        leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                                        value={this.state.searchTextContract}
                                        onChange={this.onChangeSearchTextContract}
                                    />
                                </div>
                                <div style={{marginTop: "30px"}}>
                                    <Table highlightOnHover verticalSpacing={"md"}>
                                        <Table.Thead>
                                            <Table.Tr>
                                                <Th
                                                    sorted={this.state.sortBy === 'company_name'}
                                                    reversed={this.state.sortOrder === "ASC"}
                                                    onSort={() => this.handleSortByAndOrderChange("company_name")}
                                                >
                                                    <div className={this.state.sortBy === "company_name" ? "activeMyAssignedTicketsHeader" : ""}>Company</div>
                                                </Th>
                                                <Th 
                                                    sorted={this.state.sortBy === "contract_name"}
                                                    reversed={this.state.sortOrder === "ASC"}
                                                    onSort={() => this.handleSortByAndOrderChange("contract_name")}
                                                >
                                                    <div className={this.state.sortBy === "contract_name" ? "activeMyAssignedTicketsHeader" : ""}>Contract Name</div>
                                                </Th>
                                                <Th 
                                                    sorted={this.state.sortBy === "contract_type"}
                                                    reversed={this.state.sortOrder === "ASC"}
                                                    onSort={() => this.handleSortByAndOrderChange("contract_type")}
                                                >
                                                    <div className={this.state.sortBy === "contract_type" ? "activeMyAssignedTicketsHeader" : ""}>Type</div>
                                                </Th>
                                                <Th 
                                                    sorted={this.state.sortBy === "contract_status"}
                                                    reversed={this.state.sortOrder === "ASC"}
                                                    onSort={() => this.handleSortByAndOrderChange("contract_status")}
                                                >
                                                    <div className={this.state.sortBy === "contract_status" ? "activeMyAssignedTicketsHeader" : ""}>Status</div>
                                                </Th>
                                                <Th 
                                                    sorted={this.state.sortBy === "renewal_status"}
                                                    reversed={this.state.sortOrder === "ASC"}
                                                    onSort={() => this.handleSortByAndOrderChange("renewal_status")}
                                                >
                                                    <div className={this.state.sortBy === "renewal_status" ? "activeMyAssignedTicketsHeader" : ""}>Renewal Status</div>
                                                </Th>
                                                <Th 
                                                    sorted={this.state.sortBy === "contract_number"}
                                                    reversed={this.state.sortOrder === "ASC"}
                                                    onSort={() => this.handleSortByAndOrderChange("contract_number")}
                                                >
                                                    <div className={this.state.sortBy === "contract_number" ? "activeMyAssignedTicketsHeader" : ""}>Contract Number</div>
                                                </Th>
                                                <Th 
                                                    sorted={this.state.sortBy === "vendor_name"}
                                                    reversed={this.state.sortOrder === "ASC"}
                                                    onSort={() => this.handleSortByAndOrderChange("vendor_name")}
                                                >
                                                    <div className={this.state.sortBy === "vendor_name" ? "activeMyAssignedTicketsHeader" : ""}>Vendor</div>
                                                </Th>
                                                <Th 
                                                    sorted={this.state.sortBy === "end_date"}
                                                    reversed={this.state.sortOrder === "ASC"}
                                                    onSort={() => this.handleSortByAndOrderChange("end_date")}
                                                >
                                                    <div className={this.state.sortBy === "end_date" ? "activeMyAssignedTicketsHeader" : ""}>Expiry</div>
                                                </Th>
                                            </Table.Tr>
                                        </Table.Thead>
                                        <Table.Tbody>
                                            {this.state.contractList && this.state.contractList.map((contract) => (
                                                <Table.Tr key={`${contract.company_name}-${contract.contract_name}`} onClick={() => this.goToSpecificContract(contract.id)}>
                                                    <Table.Td style={{cursor: "pointer"}}>{contract.company_name}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{contract.contract_name}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{contract.contract_type}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>
                                                        {contract.contract_status !== null ?
                                                            <Pill className={contract.contract_status === "Active" ? [contractClasses.rootActive, contractClasses.labelActive] : [contractClasses.rootInActive, contractClasses.labelInActive]}>{contract.contract_status}</Pill>
                                                            :
                                                            undefined
                                                        }
                                                    </Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{contract.renewal_status}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{contract.contract_number}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{contract.vendor_name}</Table.Td>
                                                    {contract.days_left >= this.state.greenZoneDays &&
                                                        <Table.Td style={{cursor: "pointer", color: "green"}}>
                                                            {contract.end_date !== null ? 
                                                                <Pill className={[contractClasses.rootActive, contractClasses.labelActive]}>{contract.end_date}</Pill>
                                                                :
                                                                undefined
                                                            }
                                                        </Table.Td>
                                                    }
                                                    {contract.days_left < this.state.greenZoneDays && contract.days_left > this.state.redZoneDays && 
                                                        <Table.Td style={{cursor: "pointer", color: "orange"}}>
                                                            {contract.end_date !== null ? 
                                                                <Pill className={[contractClasses.rootWarningZone, contractClasses.labelWarningZone]}>{contract.end_date}</Pill>
                                                                :
                                                                undefined
                                                            }
                                                        </Table.Td>
                                                    }
                                                    {contract.days_left <= this.state.redZoneDays && 
                                                        <Table.Td style={{cursor: "pointer", color: "red"}}>
                                                            {contract.end_date !== null ? 
                                                                <Pill className={[contractClasses.rootInActive, contractClasses.labelInActive]}>{contract.end_date}</Pill>
                                                                :
                                                                undefined
                                                            }
                                                        </Table.Td>
                                                    }
                                                </Table.Tr>
                                            ))}
                                        </Table.Tbody>
                                    </Table>
                                </div>
                            </div>
                            :
                            <div style={{marginTop: "30px"}}>
                                <Flex
                                    justify="flex-start"
                                    align="flex-start"
                                    direction="row"
                                    gap="xl"
                                >
                                    <Select
                                        label="Status"
                                        placeholder="Pick a Value"
                                        data={["All", "Disabled", "Discovered", "Ignored", "In Review", "Managed", "Restricted"]}
                                        value={this.state.selectedSoftwareStatus}
                                        onChange={(e) => this.handleSoftwareStatusFilterChange(e)}
                                    />

                                    <Select
                                        label="Managed By"
                                        placeholder="Pick a Value"
                                        data={this.state.managedByList && this.state.managedByList.map((manager) => (
                                            manager.name
                                        ))}
                                        value={this.state.managedByList && this.state.managedByList.length > 0 && this.state.managedByList.find((e) => e.id === this.state.selectedSoftwareManagedById) ? 
                                            this.state.managedByList.find((e) => e.id === this.state.selectedSoftwareManagedById).name 
                                            :
                                            ""
                                        }
                                        onChange={(e) => this.handleSoftwareManagedByFilterChange(e)}
                                        searchable
                                    />

                                    <Select
                                        label="Type"
                                        placeholder="Pick a Value"
                                        data={["All", "Desktop", "Mobile", "Saas"]}
                                        value={this.state.selectedSoftwareType}
                                        onChange={(e) => this.handleSoftwareTypeFilterChange(e)}
                                    />

                                    <Select
                                        label="Manufacturer"
                                        placeholder="Pick a Value"
                                        data={this.state.publishers && this.state.publishers.map((publisher) => (
                                            publisher.name_of_manufacturer
                                        ))}
                                        value={this.state.publishers && this.state.publishers.length > 0 && this.state.publishers.find((e) => e.id === this.state.selectedSoftwarePublisherId) ? 
                                            this.state.publishers.find((e) => e.id === this.state.selectedSoftwarePublisherId).name_of_manufacturer 
                                            :
                                            ""
                                        }
                                        onChange={(e) => this.handleSoftwarePublisherFilterChange(e)}
                                        searchable
                                    />

                                    <div style={{ marginTop: "32px" }}>
                                        <Tooltip label="Reset Filters"><Button variant="light" color="red" onClick={() => this.resetSoftwareFilters()}><IconFilterX /></Button></Tooltip>
                                    </div>
                                </Flex>
                                <div style={{marginTop: "30px"}}>
                                    <TextInput
                                        placeholder="Search"
                                        mb="md"
                                        leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                                        value={this.state.searchTextSoftware}
                                        onChange={this.onChangeSearchTextSoftware}
                                    />
                                </div>
                                <div style={{marginTop: "30px"}}>
                                    <Table highlightOnHover verticalSpacing={"md"}>
                                        <Table.Thead>
                                            <Table.Tr>
                                                <Th
                                                    sorted={this.state.softwareSortBy === "company_name"}
                                                    reversed={this.state.softwareSortOrder === "ASC"}
                                                    onSort={() => this.handleSoftwareSortByAndOrderChange("company_name")}
                                                >
                                                    <div className={this.state.softwareSortBy === "company_name" ? "activeMyAssignedTicketsHeader" : ""}>Company</div>
                                                </Th>
                                                <Th
                                                    sorted={this.state.softwareSortBy === "software_name"}
                                                    reversed={this.state.softwareSortOrder === "ASC"}
                                                    onSort={() => this.handleSoftwareSortByAndOrderChange("software_name")}
                                                >
                                                    <div className={this.state.softwareSortBy === "software_name" ? "activeMyAssignedTicketsHeader" : ""}>Software</div>
                                                </Th>
                                                <Th
                                                    sorted={this.state.softwareSortBy === "software_status"}
                                                    reversed={this.state.softwareSortOrder === "ASC"}
                                                    onSort={() => this.handleSoftwareSortByAndOrderChange("software_status")}
                                                >
                                                    <div className={this.state.softwareSortBy === "software_status" ? "activeMyAssignedTicketsHeader" : ""}>Status</div>
                                                </Th>
                                                <Th
                                                    sorted={this.state.softwareSortBy === "contract_value"}
                                                    reversed={this.state.softwareSortOrder === "ASC"}
                                                    onSort={() => this.handleSoftwareSortByAndOrderChange("contract_value")}
                                                >
                                                    <div className={this.state.softwareSortBy === "contract_value" ? "activeMyAssignedTicketsHeader" : ""}>Contract Value</div>
                                                </Th>
                                                <Th
                                                    sorted={this.state.softwareSortBy === "managed_by"}
                                                    reversed={this.state.softwareSortOrder === "ASC"}
                                                    onSort={() => this.handleSoftwareSortByAndOrderChange("managed_by")}
                                                >
                                                    <div className={this.state.softwareSortBy === "managed_by" ? "activeMyAssignedTicketsHeader" : ""}>Managed By</div>
                                                </Th>
                                                <Th
                                                    sorted={this.state.softwareSortBy === "user_number"}
                                                    reversed={this.state.softwareSortOrder === "ASC"}
                                                    onSort={() => this.handleSoftwareSortByAndOrderChange("user_number")}
                                                >
                                                    <div className={this.state.softwareSortBy === "user_number" ? "activeMyAssignedTicketsHeader" : ""}>Users</div>
                                                </Th>
                                                <Th
                                                    sorted={this.state.softwareSortBy === "install_number"}
                                                    reversed={this.state.softwareSortOrder === "ASC"}
                                                    onSort={() => this.handleSoftwareSortByAndOrderChange("install_number")}
                                                >
                                                    <div className={this.state.softwareSortBy === "install_number" ? "activeMyAssignedTicketsHeader" : ""}>Installs</div>
                                                </Th>
                                                <Th
                                                    sorted={this.state.softwareSortBy === "software_type"}
                                                    reversed={this.state.softwareSortOrder === "ASC"}
                                                    onSort={() => this.handleSoftwareSortByAndOrderChange("software_type")}
                                                >
                                                    <div className={this.state.softwareSortBy === "software_type" ? "activeMyAssignedTicketsHeader" : ""}>Type</div>
                                                </Th>
                                                <Th
                                                    sorted={this.state.softwareSortBy === "software_publisher"}
                                                    reversed={this.state.softwareSortOrder === "ASC"}
                                                    onSort={() => this.handleSoftwareSortByAndOrderChange("software_publisher")}
                                                >
                                                    <div className={this.state.softwareSortBy === "software_publisher" ? "activeMyAssignedTicketsHeader" : ""}>Manufacturer</div>
                                                </Th>
                                            </Table.Tr>
                                        </Table.Thead>
                                        <Table.Tbody>
                                            {this.state.software && this.state.software.map((software, index) => (
                                                <Table.Tr key={this.state.selectedCustomerId === 0 ? `${index}-software-${software.id}` : `${index}-${software.company_name}-${software.contract_id}-${software.id}`} onClick={() => this.goToSpecificSoftwareAsset(software.id)}>
                                                    <Table.Td style={{cursor: "pointer"}}>{software.company_name}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{software.software_name}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{software.status}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{software.contract_value !== undefined && software.contract_value !== null && `$ ${software.contract_value}`}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{software.managed_by}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{software.user_count}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{software.install_count}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{software.type}</Table.Td>
                                                    <Table.Td style={{cursor: "pointer"}}>{software.publisher}</Table.Td>
                                                </Table.Tr>
                                            ))}
                                        </Table.Tbody>
                                    </Table>
                                </div>
                            </div>
                        }
                    </Paper>
                </Container>

                <Modal
                    opened={this.state.show_customer_modal}
                    onClose={() => this.closeCustomerModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create a Customer</Text></div>
                        <Form.Group as={Col} controlId="addContractFormCreateVendorName" required>
                            <TextInput label="Customer Name" placeholder="Enter Value Here" value={this.state.create_customer_name_entered} onChange={(e) => this.handleCreateCustomerNameChange(e.target.value)} />
                        </Form.Group>
                    </Form>
                    <div style={{ textAlign: "center", marginTop: "15px" }}>
                        {this.state.canSeeErrorMessageCreateCustomer ?
                            <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                            :
                            undefined
                        }
                        {this.state.canSeeDuplicateNameErrorCreateCustomer ?
                            <Text c="red">Error: A customer with this name already exists. Please try again.</Text>
                            :
                            undefined
                        }
                    </div>
                    <div style={{textAlign: 'center', marginTop: "50px"}}>
                        <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeCustomerModal()}>Cancel</Button></span>
                        <span><Button onClick={() => this.submitCreateCustomerForm()}>Submit</Button></span>
                    </div>
                </Modal>
            </>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions,
        softwareAssetFilter: state.softwareAssetFilter
    };
}

export default connect(mapStateToProps, {
    UPDATE_ACTIVE_LINK,
    ACCESSTOKEN,
    LOGOUT,
    UPDATE_SORT_BY_SOFTWARE,
    UPDATE_SORT_ORDER_SOFTWARE,
    UPDATE_SEARCH_TEXT_SOFTWARE,
    UPDATE_SELECTED_WARRANTY_STATUS_SOFTWARE,
    UPDATE_SELECTED_EXPIRATION_START_DATE_SOFTWARE,
    UPDATE_SELECTED_EXPIRATION_END_DATE_SOFTWARE,
    UPDATE_SELECTED_LICENSE_STATUS_SOFTWARE,
    UPDATE_SELECTED_LICENSE_EXPIRATION_START_DATE_SOFTWARE,
    UPDATE_SELECTED_LICENSE_EXPIRATION_END_DATE_SOFTWARE,
    UPDATE_SELECTED_HAS_DEVICES_REMAINING_SOFTWARE,
    UPDATE_SELECTED_TENANT_ID_SOFTWARE,
    UPDATE_SELECTED_ASSIGNED_TO_USER_SOFTWARE,
    UPDATE_SELECTED_ASSIGNED_TO_ASSET_SOFTWARE,
    UPDATE_SELECTED_LICENSE_NUMBER_SOFTWARE,


    UPDATE_SELECTED_CONTRACT_CUSTOMER,
    UPDATE_SELECTED_CONTRACT_TYPE,
    UPDATE_SELECTED_CONTRACT_STATUS,
    UPDATE_SELECTED_CONTRACT_VENDOR,
    UPDATE_SELECTED_CONTRACT_END_DATE,
    UPDATE_CONTRACT_SEARCH_TEXT,
    UPDATE_SELECTED_SOFTWARE_CONTRACT_DATA_VIEW,
    UPDATE_CONTRACT_SORT_BY_AND_ORDER,
    RESET_CONTRACT_FILTERS,

    UPDATE_SOFTWARE_ASSET_STATUS_FILTER,
    UPDATE_SOFTWARE_ASSET_MANAGED_BY_FILTER,
    UPDATE_SOFTWARE_ASSET_TYPE_FILTER,
    UPDATE_SOFTWARE_PUBLISHER_FILTER,
    UPDATE_SOFTWARE_ASSET_SEARCH_TEXT,
    UPDATE_SOFTWARE_ASSET_SORT_BY_AND_SORT_ORDER,
    RESET_SOFTWARE_ASSET_FILTERS
})(ViewContractsAndSoftware);
