import { Switch, useMantineTheme, rem, useMantineColorScheme, useComputedColorScheme } from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

function ToggleColorSchemeSwitch() {
  const theme = useMantineTheme();
  const { setColorScheme } = useMantineColorScheme({ keepTransitions: true, });
  const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, [])

  const sunIcon = (
    <IconSun
      style={{ width: rem(16), height: rem(16) }}
      stroke={2.5}
      color={theme.colors.yellow[4]}
    />
  );

  const moonIcon = (
    <IconMoonStars
      style={{ width: rem(16), height: rem(16) }}
      stroke={2.5}
      color={theme.colors.blue[6]}
    />
  );

  return (
    <>
    {isClient ? <Switch
      size="md"
      onLabel={sunIcon}
      offLabel={moonIcon}
      checked={computedColorScheme === 'light'}
      color="gray"
      mt="27px"
      mr="27px"
      ml="auto"
      onChange={(event) => setColorScheme(event.currentTarget.checked ? 'light' : 'dark')}
    /> : <></>}
    </>
  );
}

export default ToggleColorSchemeSwitch;