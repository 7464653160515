import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import * as msal from "@azure/msal-browser";

import { store } from './app/store';
import { persistor } from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import { PersistGate } from 'redux-persist/integration/react';

//import http from "./services/account-activities";
//import axios from 'axios';


const msalConfig = {
  auth: {
      clientId: 'dd98df00-21e2-49b2-8c0d-bbcb04d6d222',
      // redirectUri: "your_app_redirect_uri", //defaults to application start page
      postLogoutRedirectUri:  "https://tickets.progroupco.com/" //"https://localhost:3000/"
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};

const loginRequest = {
  scopes: ["api://e07e3f36-816f-44af-8435-b89f03c0df82/Accounts.Read.All", "api://e07e3f36-816f-44af-8435-b89f03c0df82/Accounts.Write.All"]
}

//let username = "";

let accessToken;

const msalInstance = new msal.PublicClientApplication(msalConfig);



let handleLogIn = () => msalInstance.loginPopup(loginRequest)
    .then(function (loginResponse) {
        //login success
        return new Promise((resolve, reject) => {

          // In case multiple accounts exist, you can select
          const currentAccounts = msalInstance.getAllAccounts();

    
          if (currentAccounts === null) {
              // no accounts detected
              reject("No account detected!");

          } else if (currentAccounts.length > 1) {
              // Add choose account code here
              reject("More than 1 account found...");
              
          } else if (currentAccounts.length === 1) {
              let username = currentAccounts[0].username;
              resolve(username);

          }

        });
    
    }).catch(function (error) {
        //login failure
        console.log(error);
      });

async function handleLogOut(username) {
  // you can select which account application should sign out
  const logoutRequest = {
    account: msalInstance.getAccountByUsername(username)
  };

  msalInstance.logout(logoutRequest);
};


async function handleAccessTokenRequest(username) {
  const accessTokenRequest = {
    account: msalInstance.getAccountByUsername(username),
    scopes: ["api://e07e3f36-816f-44af-8435-b89f03c0df82/Accounts.Read.All", "api://e07e3f36-816f-44af-8435-b89f03c0df82/Accounts.Write.All"],
    redirectUri: "https://tickets.progroupco.com/blank.html"
  };

  //let wordToDisplay = "haha";

  return await msalInstance.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
    // Acquire token silent success
    // Call API with token
    accessToken = accessTokenResponse.accessToken;
    return accessToken;

    /* return new Promise((resolve, reject) => {
      if(accessToken == null) {
        reject("Failed to get access token!");
      }

      else {
        resolve(accessToken);
      }
    }) */

    //axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    /* http.interceptors.request.use(req => {
      // `req` is the Axios request config, so you can modify
      // the `headers`.
      req.headers.authorization = `Bearer ${accessToken}`;
      return req;
    }); */

    //console.log(accessToken);
    //console.log(wordToDisplay);
  }).catch(function (error) {
    //Acquire token silent failure, and send an interactive request
    if (error.errorMessage.indexOf("UI required") !== -1) {
        msalInstance.acquireTokenPopup(accessTokenRequest).then(function(accessTokenResponse) {
            // Acquire token interactive success
            accessToken = accessTokenResponse.accessToken;
            return accessToken;

            //axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            /* http.interceptors.request.use(req => {
              // `req` is the Axios request config, so you can modify
              // the `headers`.
              req.headers.authorization = `Bearer ${accessToken}`;
              return req;
            }); */

            //console.log(accessToken);
            //console.log("Above token was obtained from the UI required error path");
        }).catch(function(error) {
            // Acquire token interactive failure
            console.log(error);
        });
    }
    console.log(error);
  });

};

/* const accessTokenRequest = {
  account: msalInstance.getAccountByUsername(username),
  scopes: ["Accounts.Read.All", "Accounts.Write.All"]
}

let handleAccessTokenRequest = () => msalInstance.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
  // Acquire token silent success
  // Call API with token
  let accessToken = accessTokenResponse.accessToken;
  console.log(accessToken);
}).catch(function (error) {
  //Acquire token silent failure, and send an interactive request
  if (error.errorMessage.indexOf("interaction_required") !== -1) {
      msalInstance.acquireTokenPopup(accessTokenRequest).then(function(accessTokenResponse) {
          // Acquire token interactive success
          let accessToken = accessTokenResponse.accessToken;
          console.log(accessToken);
      }).catch(function(error) {
          // Acquire token interactive failure
          console.log(error);
      });
  }
  console.log(error);
}); */


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App onLogIn={handleLogIn} onLogOut={handleLogOut} onAccessTokenRequest={handleAccessTokenRequest}/>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
